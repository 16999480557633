import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert, Grid } from "@mui/material";
import { StyledButton } from './styles';

//leaflet imports
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import IncidentsTypeLegend from './IncidentsTypeLegend';

//components
import { RecenterButton } from './RecenterButton';

//constants
import { ApiEndpoints } from '../../utils/Constants';

//custom hooks
import { useFetch } from '../../hooks/useFetch';

//context
import { useAuth } from '../../context/AuthContext';

// Custom Icons
const currentIcon = new L.Icon({
    iconUrl: '/images/markers/current-location-icon.svg',
    iconSize: [25, 30],
    iconAnchor: [12.5, 30], //half of icon's width and full height
    popupAnchor: [0, -15]
});

const completeIcon = new L.Icon({
    iconUrl: '/images/markers/complete-incident-icon.svg',
    iconSize: [27, 45],
    iconAnchor: [13.5, 45], //half of icon's width and full height
    popupAnchor: [0, -27.5]
});

const openIcon = new L.Icon({
    iconUrl: '/images/markers/open-incident-icon.svg',
    iconSize: [45, 81],
    iconAnchor: [22.5, 81], //half of icon's width and full height
    popupAnchor: [0, -40.5]
});

const openCriticalIcon = new L.Icon({
    iconUrl: '/images/markers/critical-open-incident-icon.svg',
    iconSize: [30, 51],
    iconAnchor: [15, 25.5], //half of icon Size
    popupAnchor: [0, -12.75]
});

const assignedIcon = new L.Icon({
    iconUrl: '/images/markers/assigned-incident-icon.svg',
    iconSize: [45, 81],
    iconAnchor: [22.5, 81], //half of icon's width and full height
    popupAnchor: [0, -40.5]
});

//Set markers for logged incidents
const generateMarkers = (incidentsData) => {
    const markers = [];

    if (incidentsData?.length > 0) {
        incidentsData.map(incident => {
            if (incident?.location?.latitude && incident?.location?.longitude) {
                let icon;
                if (incident.status.toLowerCase() === 'complete')
                    icon = completeIcon;
                else if (incident.status.toLowerCase() === 'open' && incident.isCritical)
                    icon = openCriticalIcon;
                else if (incident.status.toLowerCase() === 'open')
                    icon = openIcon;
                else if (incident.status.toLowerCase() === 'assigned')
                    icon = assignedIcon;
                markers.push({
                    position: [incident.location.latitude, incident.location.longitude],
                    icon,
                    type: incident.status,
                    incidentId: incident.id
                });
            }
        })
    }
    return markers;
};

export const OpenIncidentMap = ({ incidentsData, showCompleted, setShowCompleted }) => {
    const [markers, setMarkers] = useState([]);
    const [currentLatitude, setCurrentLatitude] = useState(null);
    const [currentLongitude, setCurrentLongitude] = useState(null);
    const navigate = useNavigate();
    const postFetcher = useFetch("post");
    const getFetcher = useFetch("get");
    const { logout } = useAuth();

    useEffect(() => {

        //Set current location: latitude and longitude.
        if (navigator?.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const lat = position.coords.latitude;
                    const lng = position.coords.longitude
                    setCurrentLatitude(lat);
                    setCurrentLongitude(lng);
                    setMarkers(generateMarkers(incidentsData));
                },
                (error) => {
                    console.error('Error getting location:', error);
                }
            );
        } else {
            console.error('Geolocation is not supported by this browser.');
        }
    }, []);

    const handleYesClick = async (position, incidentId) => {
        if (position?.length > 0 && incidentId) {
            let updatedIncident = null;

            //Copy coordinates to clipboard.
            const lat = position[0];
            const lng = position[1];
            const coords = `${lat},${lng}`;
            navigator.clipboard.writeText(coords);

            //fetch incident
            try {
                const response = await getFetcher.setRequest({
                    endpoint: '/patroller' + ApiEndpoints["API_GET_INCIDENT_BY_ID"] + "?id=" + incidentId
                });
                if (response) {
                    if (!response.error && response.data?.incident) { //if no error

                        const postEditResponse = await postFetcher.setRequest({
                            endpoint: "/patroller" + ApiEndpoints["API_POST_EDIT_INCIDENT"],
                            body: JSON.stringify({
                                ...response.data.incident,
                                "callInTime": response.data.incident.callInTime ? new Date(response.data.incident.callInTime) : "",
                                "f1017": response.data.incident.f1017 ? new Date(response.data.incident.f1017) : "",
                                "shiftId": JSON.parse(sessionStorage.getItem('user'))?.shift?.id || null,
                                "status": "assigned"
                            })
                        });
                        if (postEditResponse?.error && (postEditResponse.errorStatus === 403 || postEditResponse.errorStatus === 401)) //logout if forbidden or unauthorized
                            logout();
                        else if (postEditResponse?.data?.incident)
                            updatedIncident = postEditResponse.data.incident;
                    }
                    else if (response.error && (response.errorStatus === 403 || response.errorStatus === 401)) //logout if forbidden or unauthorized
                        logout();
                }

                //Navigate to add incident page to complete incident.        
                navigate('/patroller/add-incident', { state: { incident: updatedIncident } });
            }
            catch (error) {
                console.error('Error fetching incident for patroller:', error)
            }
        }
    }

    if (!currentLatitude || !currentLongitude) {
        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <MapContainer center={[0, 0]} zoom={15} minZoom={6} style={{ height: "100vh", width: "100%", zIndex: 1 }}>
                        <Alert severity="info">Loading map. Thank you for your patience.</Alert>
                        <TileLayer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        />
                    </MapContainer>
                </Grid>
            </Grid>
        )
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <div style={{ position: 'relative', height: '100vh', width: '100%' }}>
                    <MapContainer center={[currentLatitude, currentLongitude]} zoom={15} minZoom={6}
                        style={{ height: "100vh", width: "100%", zIndex: 1 }}>
                        <TileLayer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        />
                        {markers.map((marker, index) => {
                            return (
                                <Marker key={index} position={marker.position} icon={marker?.icon}>
                                    <Popup>
                                        {marker.type.toLowerCase() === 'open' && (
                                            <div style={{ textAlign: "center" }}>
                                                <p>Do you want to complete this incident?</p>
                                                <StyledButton variant="text" onClick={() => { handleYesClick(marker.position, marker.incidentId) }}>Yes</StyledButton>
                                            </div>
                                        )}
                                        {marker.type.toLowerCase() === "complete" && (
                                            <p>This incident is complete.</p>
                                        )}
                                        {marker.type.toLowerCase() === "critical" && (
                                            <div style={{ textAlign: "center" }}>
                                                <p>This incident is <strong>critical</strong>. Do you want to complete it?</p>
                                                <StyledButton variant="text" onClick={() => { handleYesClick(marker.position, marker.incidentId) }}>Complete</StyledButton>
                                            </div>
                                        )}
                                        {marker.type.toLowerCase() === "assigned" && (
                                            <p>Someone is working on this incident.</p>
                                        )}
                                    </Popup>
                                </Marker>
                            );
                        })}
                        <Marker
                            position={[currentLatitude, currentLongitude]}
                            icon={currentIcon}>
                            <Popup>You are here</Popup>
                        </Marker>
                        <RecenterButton lat={currentLatitude} lng={currentLongitude} zoomLevel={15} />
                    </MapContainer>
                    <IncidentsTypeLegend
                        style={{ position: 'absolute', top: '12px', right: '10px', zIndex: 2 }}
                        type="patroller-map"
                        showCompleted={showCompleted}
                        setShowCompleted={setShowCompleted}
                    />
                </div>
            </Grid>
        </Grid>
    );
};